import * as React from "react";
// import firebase from "firebase/app";
// import "firebase/database";
// import {
//   FirebaseDatabaseProvider,
//   FirebaseDatabaseNode
// } from "@react-firebase/database";
import "./css/userdata.css"
import { firebaseConfigs, checkSession, fluentd_s3} from './api/comm';
import { nexSignin, nexGrantAccess } from './api/nex';
import { getPointData } from './api/backend';
// import 'firebase/auth';
import Select from 'react-select';
import ReactNotify from 'react-notify';
import ReactLoading from 'react-loading';
import ReactJson from 'react-json-view';

const options = [
  { value: 'account', label: 'Search by dealer account' },
  { value: 'pointid', label: 'Search by point ID' }
];

const Loading = () => (
  <ReactLoading type="spinningBubbles" color="#ff8800" height={'50%'} width={'50%'} />
);

// const s = (a: any) => JSON.stringify(a, null, 4);
let responseData = {}

export class Pointdata extends React.Component {
  constructor(props) {
    super(props);

    this.sessionData = checkSession()

    this.options = [
      { value: 'account', label: 'Search by dealer account' },
      { value: 'pid', label: 'Search by point ID' }
    ];
    
    this.state = {
      limit: 0,
      path: "",
      inputdata: "",
      // type: "account",
      // pid: "",
      account: "",
      visible: this.options[0].value,
      label: this.options[0].label,
      // customer: this.customeroptions[0].value,
      env: 'prod',

      // email: firebaseAuth.account,
      // passwordOne: firebaseAuth.password,

      selectedOption: this.options[0],
      // selectedCustomerOption: this.customeroptions[0],
      waitcursor: false,
      showerror: false

    }
    this.firebaseConfig =
      this.sessionData[1] ? firebaseConfigs[this.sessionData[1].env] : firebaseConfigs['prod'];

    // if (!firebase.apps.length) {
    //   firebase.initializeApp(this.firebaseConfig);

    // }

    // this.auth = firebase.auth();

  }


  // doSignInWithEmailAndPassword = (email, password) =>
  //   this.auth.signInWithEmailAndPassword(email, password);



  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value.replace(/\s/g, ''),
    })
    e.preventDefault();
  }

  selectHandleChange = (selectedOption) => {
    this.setState({
      visible: selectedOption.value,
      label: selectedOption.label,
      selectedOption
    })
  }

  // selectCustomerHandleChange = (selectedCustomerOption) => {
  //   this.setState({
  //     customer: selectedCustomerOption.value,
  //     selectedCustomerOption
  //   })

  // }

  // selectHandleChange = (selectedOption) => {
  //   this.setState({
  //     type: selectedOption.value,
  //     selectedOption,
  //     inputdata: ""
  //   })
  // }

  handleSubmit(event) {
    // const { email, passwordOne } = this.state;


    if (this.state.eid === "" || this.state.visible === "" ) {
      this.showNotification('ERROR', 'Please input engry station id');
      // return
    } else {
      let log_body = {
        category: "getPointData",
        env: this.sessionData[1].env,
        uid: this.sessionData[1]['mystiqueAuth'].uid,
        // eid: this.state.eid,
        visible: this.state.visible,
        // customer: this.state.customer,
        name: this.state.label
      }
      responseData = {}
      this.setState({ waitcursor: true })
      nexSignin(this.sessionData[1])
        .then((data) => {
          
          return nexGrantAccess(data)
        }).then(data => {
          let temp = {}
          if (this.state.visible === "account"){
            temp = { account: this.state.inputdata, 'auth': this.sessionData[1] }
          } else {
            temp = { pid: this.state.inputdata, 'auth': this.sessionData[1] }
          }
          return getPointData(temp)
        }).then(data => {
        
          responseData = data['getPointData']
          log_body['status'] = 'success'
          fluentd_s3(log_body)
          
          this.setState({ waitcursor: false , inputdata: "" })
          
          // return
        })
        .catch(error => {
         
          log_body['status'] = error
          console.log(log_body)
          fluentd_s3(log_body)
          this.setState({ waitcursor: false , inputdata: "" })
        });
    }
    event.preventDefault();
  }

  componentDidMount() {
    const session = checkSession()
    if (!session[0]) {
      // this.props.history.push("/login");
    }
    document.title = "Ionex Admin System";

  }

  showNotification(type, content) {

    if (type === 'INFO') this.refs.notificator.info(type, content, 3000);
    if (type === 'ERROR') {
      this.setState({
        path: '',
        eid: '',
        visible: '',
        waitcursor: false
      });
      this.refs.notificator.error(type, content, 3000);
    }
  }

  render() {
    const env = this.sessionData[1] ? this.sessionData[1].env : "null"
    const { selectedOption, customer } = this.state;
    let display =
      <div id="parent">
        <pre>經銷商資料查詢 <br />(current env:{env})
          </pre>
          <form onSubmit={(e) => this.handleSubmit(e)}>

            <Select
              id="type"
              name="type"
              value={selectedOption || options[0]}
              onChange={this.selectHandleChange}
              options={options}
              placeholder="Select query method"
            />
            <label>
              <input placeholder="Please input data" type="text" id="inputdata" value={this.state.inputdata} onChange={e => this.handleChange(e)} />
            </label>
            <br />
            <input type="submit" value="Submit" style={{ fontSize: '15px', height: "30px", width: "380px" }} />
            {this.state.waitcursor ? Loading() : <div />}
          </form>
       
        <div align="left"  >
        <ReactJson src={responseData} />
    </div>
       
        <ReactNotify ref='notificator' />

        </div>;


    return (
      <div>
        {display}

      </div>
    );

  }
}

