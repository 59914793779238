import * as React from "react";

// import "firebase/database";

import "./css/changeplan.css"
import { checkSession, fluentd_s3, speciallist, bidlist, eidlist } from './api/comm';
import { getBatteryHistory } from './api/backend';
// import 'firebase/auth';

import ReactNotify from 'react-notify';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';



import { CSVLink } from 'react-csv';


const options = [
  { value: 'bid', label: 'Search by Battery ID' },
  { value: 'pid', label: 'Search by Energy Station ID' },
  { value: 'uid', label: 'Search by User ID' }
];

const offset = new Date().getTimezoneOffset();
function timeConverter(UNIX_timestamp) {
  function coverterNumber(number) {
    return number < 10 ? "0" + number.toString() : number.toString()
  };
  let a = new Date(UNIX_timestamp);
  let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let year = a.getFullYear();
  let month = months[a.getMonth()];
  let date = coverterNumber(a.getDate());
  let hour = coverterNumber(a.getHours());
  let min = coverterNumber(a.getMinutes());
  let sec = coverterNumber(a.getSeconds());
  let time = `${year}-${month}-${date} ${hour}:${min}:${sec}`;
  return time;
}



const classes = PropTypes.object.isRequired;

const Loading = () => (
  <ReactLoading type="spinningBubbles" color="#ff8800" height={'20%'} width={'20%'} />
);

const compareList = (list1, list2) => {
  for (let data of list2){
    if (!list1.includes(data)) {
       return false
    }
  }
  return true
}

const bid_headers = [
  { label: "Happen time", key: "createdAt" },
  { label: "Action", key: "action" },
  { label: "Battery_ID", key: "bid" },
  { label: "From", key: "from" },
  { label: "To", key: "to" }
];



const History_table = () => {
  return (<Paper className={classes.root}>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell align="left">{`Happen time (UTC${offset*-1/60})`}</TableCell>
          <TableCell align="left">Action</TableCell>
          <TableCell align="left">Battery ID</TableCell>
          <TableCell align="left">From</TableCell>
          <TableCell align="left">To</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <TableRow key={row.id}>
            <TableCell component="th" scope="row">
              {row.createdAt}
            </TableCell>
            <TableCell align="left">{row.action}</TableCell>
            <TableCell align="left">{row.bid}</TableCell>
            <TableCell align="left">{row.from}</TableCell>
            <TableCell align="left">{row.to}</TableCell>
          </TableRow>
        ))}

      </TableBody>
    </Table>
  </Paper>
  )
}

const Bid_table = () => {
  return (<Paper className={classes.root}>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell align="left">{`Last charge time (UTC${offset*-1/60})`}</TableCell>
          <TableCell align="left">Battery ID</TableCell>
          <TableCell align="left">EV</TableCell>
          <TableCell align="left">State</TableCell>
          <TableCell align="left">RSOC</TableCell>
          <TableCell align="left">VPACK</TableCell>
          <TableCell align="left">BMS</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {brows.map(brow => (
          <TableRow key={brow.id}>
            <TableCell component="th" scope="brow">
              {brow.createdAt}
            </TableCell>
            <TableCell align="left">{brow.bid}</TableCell>
            <TableCell align="left">{brow.ev}</TableCell>
            <TableCell align="left">{brow.state}</TableCell>
            <TableCell align="left">{brow.rsoc}</TableCell>
            <TableCell align="left">{brow.vpack}</TableCell>
            <TableCell align="left">{brow.bms}</TableCell>
          </TableRow>
        ))}

      </TableBody>
    </Table>
  </Paper>

  )
}


let brows = []
let rows = []


const history_convert = (records) => {
  
  // rows = []
  let i = 0
  for (let record of records['getBatteryHistory']) {
    i += 1;
    let row_temp = {
      id: i,
      bid: record['data']['bid'],
      action: record['data']['action'],
      createdAt: timeConverter(record['data']['createdAt'])
    }
    if (record['tid'].indexOf('-rebind-') > -1 || record['tid'].indexOf('-bind-') > -1 || record['tid'].indexOf('-charge-') > -1) {
      row_temp['from'] = `${record['data']['pid']}`
      row_temp['to'] = `${record['data']['uid']}`
      rows.unshift(row_temp);
    }
    if (record['tid'].indexOf('-unbind-') > -1) {
      row_temp['from'] = `${record['data']['uid']}`
      row_temp['to'] = `${record['data']['pid']}`
      rows.unshift(row_temp);
    }
    if (record['tid'].indexOf('-receive-') > -1 || record['tid'].indexOf('-return-') > -1) {
      row_temp['from'] = `${record['data']['from']}`
      row_temp['to'] = `${record['data']['to']}`
      rows.unshift(row_temp);
    }
  }
}


const bid_convert = (records) => {
  
  // rows = []
  let i = 0
  for (let record of records['getChargeHistory']) {
    i += 1;
    let row_temp = {
      id: i,
      bid: record['data']['bid'],
      ev: record['data']['ev'],
      
      state: record['data']['state'],
      rsoc: record['data']['RSOC'].toString(),
      vpack: record['data']['VPack'].toString(),
      bms: record['data']['BMS'],
      createdAt: timeConverter(record['data']['createdAt'])
    }
    brows.unshift(row_temp);
   
  }
}

export class GetBatteryHistory extends React.Component {
  constructor(props) {
    super(props);
    this.sessionData = checkSession()

    this.state = {
      env: 'prod',
      bid: '',
      inputdata: "",
      showtable: true,
      waitcursor: false,
      selectedOption: null,
      bid_display: false,
      error:false,
      type: "bid"
    }

  }



  

  handleSubmit(event) {
    rows = [];
    brows = [];
    this.setState({ waitcursor: false, showtable: false })
    if (this.state.inputdata === '' || 
       (speciallist.indexOf(this.sessionData[1]['mystiqueAuth'].email)  > -1 && this.state.type === 'bid' && !compareList(bidlist, this.state.inputdata.split(","))) ||
       (speciallist.indexOf(this.sessionData[1]['mystiqueAuth'].email)  > -1 && this.state.type === 'pid' && !compareList(eidlist, this.state.inputdata.split(","))) 
       
    ) {
      this.showNotification('ERROR', 'Wrong !!! Please re-input data');
    } else if (speciallist.indexOf(this.sessionData[1]['mystiqueAuth'].email)  > -1 && this.state.type === 'uid') {
      this.showNotification('ERROR', 'Wrong !!! only open search by battery and energy station');
    } else {

      let log_body = {
        category: "getBatteryHistory_mongo",
        env: this.sessionData[1].env,
        inputdata: this.state.inputdata,
        uid: this.sessionData[1]['mystiqueAuth'].uid,
      }

      this.setState({ waitcursor: true })
      
      let ctx = { 'auth': this.sessionData[1] }
      if (this.state.type === 'bid') ctx['bid'] = this.state.inputdata.split(',')
      if (this.state.type === 'pid') ctx['pid'] = this.state.inputdata
      if (this.state.type === 'uid') ctx['uid'] = this.state.inputdata
      getBatteryHistory(ctx)
        .then(function (data) {
          // console.log(data);
          history_convert(data)
          bid_convert(data)
        
          log_body['status'] = 'success'
          fluentd_s3(log_body)
          this.setState({ waitcursor: false});
        })
        .catch(error => {
          // this.showNotification('ERROR', 'Unable find battery data or you need re-login account');
          log_body['status'] = error
          fluentd_s3(log_body)
          this.setState({ waitcursor: false });
        });
    }
    event.preventDefault();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  selectHandleChange = (selectedOption) => {
    this.setState({
      type: selectedOption.value,
      selectedOption,
      inputdata: ""
    })
  }

  componentDidMount() {
    const session = checkSession()
    if (!session[0]) {
      // this.props.history.push("/login");
    }
    document.title = "Ionex Admin System";
  }

  showNotification(type, content) {
    if (type === 'INFO') this.refs.notificator.info(type, content, 3000);
    if (type === 'ERROR') {
      this.setState({
        waitcursor: false
      });
      this.refs.notificator.error(type, content, 3000);
    }
  }

  render() {
    const env = this.sessionData[1] ? this.sessionData[1].env : "null"
    const { selectedOption } = this.state;


    let display =
      <div id="parent">
        <pre>Battery History (current env:{env})
        <br/> {env === 'prod' ? <div>Only search data for 60 days</div> : <div>Only search data for 90 days</div> }

          </pre>

          <form onSubmit={(e) => this.handleSubmit(e)}>

            <Select
              id="type"
              name="type"
              value={selectedOption || options[0]}
              onChange={this.selectHandleChange}
              options={options}
              placeholder="Select query method"
            />
          

          <label>
            <input placeholder="Please input data" style={{ fontSize: '15px', height: "30px", width: "370px" }} id="inputdata" value={this.state.inputdata} onChange={e => this.handleChange(e)} />
          </label>
           
          <br />
          { <button type="submit" id="search" value="開始搜尋" style={{ fontSize: '15px', height: "30px", width: "380px" }} onClick={(e) => this.handleSubmit(e)}>Search</button> }
          <br />
          {rows.length > 0 ? <CSVLink data={rows} headers={bid_headers} filename={"ionex-battery.csv"}>Output CSV</CSVLink> : <div />}

          {this.state.waitcursor ? Loading() : <div />}
        </form>
        <ReactNotify ref='notificator' />
        {brows.length > 0 ? <div><Bid_table /> <br/> <br/></div>: <div />} 
     <History_table />

        
      </div>;

    return (
      <div>
        {display}
      </div>
    );

  }
}

