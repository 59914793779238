import * as React from "react";
// import firebase from "firebase/app";
// import "firebase/database";
// import {
//   FirebaseDatabaseProvider,
//   FirebaseDatabaseNode
// } from "@react-firebase/database";
import "./css/userdata.css"
import { firebaseConfigs, checkSession, firebaseAuth, fluentd_s3} from './api/comm';
import { nexSignin, nexGrantAccess, nexUpdateEngryStation } from './api/nex';
import { getPointData } from './api/backend';
// import 'firebase/auth';
import Select from 'react-select';
import ReactNotify from 'react-notify';
import ReactLoading from 'react-loading';
import ReactJson from 'react-json-view';


const Loading = () => (
  <ReactLoading type="spinningBubbles" color="#ff8800" height={'50%'} width={'50%'} />
);

const s = (a: any) => JSON.stringify(a, null, 4);
let responseData = {}

export class Cabinetvisible extends React.Component {
  constructor(props) {
    super(props);

    this.sessionData = checkSession()
    this.options = [
      { value: true, label: '電櫃上架' },
      { value: false, label: '電櫃下架' },
    ]
    this.customeroptions = [
      { value: 'ionextw', label: 'Ionex-TW' },
      { value: 'iusa', label: 'iUSA' },
    ]
    this.state = {
      limit: 0,
      path: "",
      eid: "",
      visible: this.options[0].value,
      label: this.options[0].label,
      customer: this.customeroptions[0].value,
      env: 'prod',

      email: firebaseAuth.account,
      passwordOne: firebaseAuth.password,

      selectedOption: this.options[0],
      selectedCustomerOption: this.customeroptions[0],
      waitcursor: false,
      showerror: false

    }
    // this.firebaseConfig =
    //   this.sessionData[1] ? firebaseConfigs[this.sessionData[1].env] : firebaseConfigs['prod'];

    // if (!firebase.apps.length) {
    //   firebase.initializeApp(this.firebaseConfig);

    // }

    // this.auth = firebase.auth();

  }


  // doSignInWithEmailAndPassword = (email, password) =>
  //   this.auth.signInWithEmailAndPassword(email, password);



  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value.replace(/\s/g, ''),
    })
    e.preventDefault();
  }

  selectHandleChange = (selectedOption) => {
    this.setState({
      visible: selectedOption.value,
      label: selectedOption.label,
      selectedOption
    })
  }

  selectCustomerHandleChange = (selectedCustomerOption) => {
    this.setState({
      customer: selectedCustomerOption.value,
      selectedCustomerOption
    })

  }



  handleSubmit(event) {
    // const { email, passwordOne } = this.state;


    if (this.state.eid === "" || this.state.visible === "" ) {
      this.showNotification('ERROR', 'Please input engry station id');
      // return
    } else {
      let log_body = {
        category: "changeCabinetVisible",
        env: this.sessionData[1].env,
        uid: this.sessionData[1]['mystiqueAuth'].uid,
        eid: this.state.eid,
        visible: this.state.visible,
        customer: this.state.customer,
        name: this.state.label
      }
      responseData = {}
      this.setState({ waitcursor: true })
      nexSignin(this.sessionData[1])
        .then((data) => {
          // console.log(data)
          return nexGrantAccess(data)
        }).then((data) => {
          // console.log(data)
          return nexUpdateEngryStation(Object.assign({ eid: this.state.eid, body: { visible: this.state.visible} }, this.sessionData[1], { customer: this.state.customer}))
        // }).then(data => {
        //   // console.log(data);
        //   return this.doSignInWithEmailAndPassword(email, passwordOne)
  
        }).then(authUser => {
          // console.log(data)
          return getPointData({ pid: this.state.eid, 'auth': this.sessionData[1] })
        }).then(data => {
          // this.setState({ path: "users/", limit: 1 });
          // console.log(data['getPointData'])
          responseData = data['getPointData']
          log_body['status'] = 'success'
          fluentd_s3(log_body)
          this.showNotification('INFO', 'Update Energy station done');
          this.setState({ waitcursor: false , eid: "" })
          
          // return
        })
        .catch(error => {
          this.showNotification('ERROR', 'Unable change cabinet visible or you need re-login account');
          // console.log("error", error)
          log_body['status'] = error
          console.log(log_body)
          fluentd_s3(log_body)
          this.setState({ waitcursor: false , eid: "" })
        });
    }
    event.preventDefault();
  }

  componentDidMount() {
    const session = checkSession()
    if (!session[0]) {
      // this.props.history.push("/login");
    }
    document.title = "Ionex Admin System";

  }

  showNotification(type, content) {

    if (type === 'INFO') this.refs.notificator.info(type, content, 3000);
    if (type === 'ERROR') {
      this.setState({
        path: '',
        eid: '',
        visible: '',
        waitcursor: false
      });
      this.refs.notificator.error(type, content, 3000);
    }
  }

  render() {
    const env = this.sessionData[1] ? this.sessionData[1].env : "null"
    const { selectedOption, selectedCustomerOption, customer } = this.state;
    let display =
      <div id="parent">
        <pre>電櫃上下架 <br />(current env:{env})
          </pre>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <label>
            <input placeholder="請輸入能源站編號" type="text" id="eid" value={this.state.eid} onChange={e => this.handleChange(e)} style={{ fontSize: '15px', height: "30px", width: "380px" }}/>
          </label>
          <Select
            id="visible"
            name="visible"
            value={selectedOption}
            onChange={this.selectHandleChange}
            options={this.options}
            placeholder="選擇上架或下架"
          />
          <Select
            id="customer"
            name="customer"
            value={selectedCustomerOption}
            onChange={this.selectCustomerHandleChange}
            options={this.customeroptions}
            placeholder="選擇客戶"
          />
         
          <input type="submit" value="Submit" style={{ fontSize: '15px', height: "30px", width: "380px" }} />
          {this.state.waitcursor ? Loading() : <div />}
        </form>
        {customer === 'ionextw'? 
        <div align="left"  >
        <ReactJson src={responseData} />
      </div>
        : <div />}

        <ReactNotify ref='notificator' />

        </div>;


    return (
      <div>
        {display}

      </div>
    );

  }
}

