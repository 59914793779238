import { axiosRequest, server } from './comm.js'

export async function  getBatteryHistory(jsondata) {
    let data = {
        method: 'post',
        url : `${server.backend.url}/getBatteryHistory`,
        postData : {
            env: jsondata.auth.env
        },
        config : {
          headers: {
            'Content-Type': 'application/json'
          }
        }
    }
    if (jsondata.bid) data.postData.find = {'data.bid': { $in: jsondata.bid}}
    if (jsondata.uid) data.postData.find = {'data.uid': jsondata.uid}
    if (jsondata.pid) data.postData.find = {$or : [{'data.pid': jsondata.pid}, {'data.from': jsondata.pid},{'data.to': jsondata.pid}]}

    if (jsondata.auth.env === 'prod'){
     
        data.postData.starttime = Date.now()-(60*24*60*60*1000);
    } else {
        data.postData.starttime = Date.now()-(90*24*60*60*1000);
    }
    
    jsondata['getBatteryHistory'] = await axiosRequest(data);

   
    if (jsondata.bid) {
      data['url'] = `${server.backend.url}/getChargeHistory`;
      let temp = []
      for (let bid of jsondata.bid){
        data.postData.find = { $query:{'data.bid': bid}};
        let result = await axiosRequest(data);
        temp.push(result[0]);
      }
      jsondata['getChargeHistory'] = temp;
    }


    return jsondata
} 

export async function  getBillingDetail(jsondata) {
  let data = {
      method: 'post',
      url : `${server.backend.url}/getBillingDetail`,
      postData : {
          env: jsondata.auth.env,
      },
      config : {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        }
      }
  }
  data.postData.find = {'uid': jsondata.uid}
  if (jsondata['starttime'] && jsondata['endtime']) {
    data.postData.find['createdAt'] = {$gte: jsondata['starttime'], $lt: jsondata['endtime']}
 
  }
  jsondata['getBillingDetail'] = await axiosRequest(data);
  return jsondata
}

export async function  getBillingDetailsV2(jsondata) {
  let data = {
      method: 'post',
      url : `${server.backend.url}/getBillingDetailsV2`,
      postData : {
          env: jsondata.auth.env,
      },
      config : {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        }
      }
  }
  data.postData.find = {'userId': jsondata.uid}
  if (jsondata['starttime'] && jsondata['endtime']) {
    data.postData.find['createdAt'] = {$gte: jsondata['starttime'], $lt: jsondata['endtime']}
 
  }
  jsondata['getBillingDetailsV2'] = await axiosRequest(data);
  return jsondata
} 

export async function  getPointData(jsondata) {
  let data = {
      method: 'post',
      url : `${server.backend.url}/getpointdata`,
      postData : {
          env: jsondata.auth.env
      },
      config : {
        headers: {
          'Content-Type': 'application/json'
        }
      }
  }
  if (jsondata.pid) {
    data.postData.find = {'pointId': jsondata.pid}
  } else {
    data.postData.find = {'userInfo.KUAccount': jsondata.account}
  }
  jsondata['getPointData'] = await axiosRequest(data);
  return jsondata
} 

export async function  getInvoiceData(jsondata) {
  let data = {
      method: 'post',
      url : `${server.backend.url}/getinvoicedata`,
      postData : {
          env: jsondata.auth.env
      },
      config : {
        headers: {
          'Content-Type': 'application/json'
        }
      }
  }
  if (jsondata.uid) {
    data.postData.find = {'uid': jsondata.uid}
  }
  jsondata['getInvoiceData'] = await axiosRequest(data);
  return jsondata
} 

export async function  getUserData(jsondata) {
  let data = {
      method: 'post',
      url : `${server.backend.url}/getuserdata`,
      postData : {
          env: jsondata.auth.env
      },
      config : {
        headers: {
          'Content-Type': 'application/json'
        }
      }
  }
  if (jsondata.idcard) {
    data.postData.find = {'kynetAccount': jsondata.idcard}
  } else if (jsondata.email) {
    data.postData.find = {'email': jsondata.email}
  } else {
    data.postData.find = {'userId': jsondata.userid}
  }
  jsondata['getUserData'] = await axiosRequest(data);
  return jsondata
} 

export async function  getMystiqueValidationData(jsondata) {
  let data = {
      method: 'post',
      url : `${server.backend.url}/getmystiquevalidationdata`,
      postData : {
          env: jsondata.auth.env
      },
      config : {
        headers: {
          'Content-Type': 'application/json'
        }
      }
  }
  data.postData.find = {'uidOrEmail': jsondata.userid}
  jsondata['getMystiqueValidationData'] = await axiosRequest(data);
  return jsondata
} 

export async function  getMystiqueUserData(jsondata) {
  let data = {
      method: 'post',
      url : `${server.backend.url}/getmystiqueuserdata`,
      postData : {
          env: jsondata.auth.env
      },
      config : {
        headers: {
          'Content-Type': 'application/json'
        }
      }
  }
  if (jsondata.hasOwnProperty("userid")) {
    data.postData.find = {'userId': jsondata.userid}
  }
  if (jsondata.hasOwnProperty("email")) {
    data.postData.find = { $or: [ { "email": jsondata.email }, { "ep.email" : jsondata.email } ] }
  }
  if (jsondata.hasOwnProperty("idcard")) {
    data.postData.find = {  "kn.account" : jsondata.idcard }
  }
  jsondata['getMystiqueUserData'] = await axiosRequest(data);
  return jsondata
} 

export async function  getVehicleData(jsondata) {
  let data = {
      method: 'post',
      url : `${server.backend.url}/getvehicledata`,
      postData : {
          env: jsondata.auth.env
      },
      config : {
        headers: {
          'Content-Type': 'application/json'
        }
      }
  }
  if (jsondata.vcu) {
    data.postData.find = {'vcu': jsondata.vcu}
  } else if (jsondata.plateId) {
    data.postData.find = {'plateId': jsondata.plateId}
  } else if (jsondata.engineNo) {
    data.postData.find = {'engineNo': jsondata.engineNo}
  } else {
    data.postData.find = {'userId': jsondata.uid}
  }
  jsondata['getVehicleData'] = await axiosRequest(data);
  return jsondata
} 

export async function  unlockSwapStatus(jsondata) {
  let data = {
      method: 'patch',
      url : `${server.backend.url}/unlockSwapStatusV2`,
      postData : {
          env: jsondata.auth.env
      },
      config : {
        headers: {
          'Content-Type': 'application/json'
        }
      }
  }
  if (jsondata.uid) {
    data.postData.find = {'userId': jsondata.uid}
  }
  jsondata['unlockSwapStatus'] = await axiosRequest(data);
  return jsondata
} 

export async function  getUsageHistoryData(jsondata) {
  let data = {
      method: 'post',
      url : `${server.backend.url}/getusagehistory`,
      postData : {
          env: jsondata.auth.env
      },
      config : {
        headers: {
          'Content-Type': 'application/json'
        }
      }
  }
  if (jsondata.uid) {
    data.postData.find = {'userId': jsondata.uid}
  }
  jsondata['getUsageHistory'] = await axiosRequest(data);
  return jsondata
} 

export async function  getPlanHistoryData(jsondata) {
  let data = {
      method: 'post',
      url : `${server.backend.url}/getplanhistorydata`,
      postData : {
          env: jsondata.auth.env
      },
      config : {
        headers: {
          'Content-Type': 'application/json'
        }
      }
  }
  if (jsondata.uid) {
    data.postData.find = {'userId': jsondata.uid}
  }
  jsondata['getPlanHistoryData'] = await axiosRequest(data);
  return jsondata
} 

export async function  getBillsData(jsondata) {
  let data = {
      method: 'post',
      url : `${server.backend.url}/getBillsData`,
      postData : {
          env: jsondata.auth.env,
      },
      config : {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        }
      }
  }
  data.postData.find = {'userId': jsondata.uid}
  if (jsondata['starttime'] && jsondata['endtime']) {
    data.postData.find['createdAt'] = {$gte: jsondata['starttime'], $lt: jsondata['endtime']}
 
  }
  jsondata['getBillsData'] = await axiosRequest(data);
  return jsondata
}
