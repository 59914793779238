import * as React from "react";

import "./css/updateuserpw.css"
import { checkSession , fluentd_s3} from './api/comm';
import { nexGrantAccess, nexSignin, nexUpdateUserPassword} from './api/nex';
import ReactNotify from 'react-notify';

import ReactLoading from 'react-loading';


const Loading = () => (
  <ReactLoading type="spinningBubbles" color="#ff8800" height={'20%'} width={'20%'} />
);


export class Update21UserPassword extends React.Component {
  constructor(props) {
    super(props);
  
    this.sessionData =  checkSession()
   
    this.state = {
      idcard: "",
      newpw: "",
      env: 'prod',
      waitcursor: false
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value.replace(/\s/g, ''),
    })
    e.preventDefault();
  }

  handleSubmit(event) {
    if (this.state.idcard === "" || this.state.newpw ==="") {
      this.showNotification('ERROR','Please input idcard or new password');
      // return
    } else {
      this.setState({
        waitcursor: true
       });
    let log_body = {
      category: "update21UserPassword",
      env: this.sessionData[1].env,
      uid: this.sessionData[1]['mystiqueAuth'].uid,
      idcard: this.state.idcard,
      newpw: this.state.newpw
    }

    nexSignin(this.sessionData[1])
    .then((data) => {
        return nexGrantAccess(data)
    }).then((data) => {
      return nexUpdateUserPassword( Object.assign({body:{idcard:this.state.idcard, newPassword:this.state.newpw}}, this.sessionData[1]))
    }).then(data => {
        this.showNotification('INFO',`Update ${this.state.idcard} password Done`);
        this.setState({
          idcard: '',
          newpw: '',
          waitcursor: false
         });
         log_body['status'] = 'success'
         this.setState({waitcursor: false})
       return fluentd_s3(log_body)
    
      })
      .catch(error => {
           this.showNotification('ERROR',`Unable update ${this.state.idcard} password or you need re-login account`);
           log_body['status'] = error
           fluentd_s3(log_body)
           this.setState({waitcursor: false})
      });
    }
    event.preventDefault();
  }


  componentDidMount() {
    const session = checkSession()
    if (!session[0]){
        // this.props.history.push("/login");
    }
    document.title = "Noodoe Admin System";
    
  }

  showNotification(type, content) {
    
    if ( type === 'INFO') this.refs.notificator.info(type, content, 3000);
    if ( type === 'ERROR') {
      this.setState({
        idcard: '',
        newpw: '',
        waitcursor: false
       });
      this.refs.notificator.error(type, content, 3000);
    }
  }
  
  render() {
    const env = this.sessionData[1] ? this.sessionData[1].env : "null";
   
    let display =
      <div id="parent">
        <pre>更新 Ionex 2.1 使用者初始密碼 (current env:{env})
          </pre>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <label>

            <input placeholder="請輸入身份證字號" type="text" id="idcard" value={this.state.idcard} onChange={e => this.handleChange(e)} />
          </label>
          <br/>
          <label>
            <input placeholder="請輸入新密碼" type="text" id="newpw" value={this.state.newpw} onChange={e => this.handleChange(e)} />
          </label>
          <br />
          <input type="submit" value="Submit" style={{ fontSize: '24px', height: "30px", width: "600px" }} />
        </form>
        <ReactNotify ref='notificator' />
        {this.state.waitcursor ? Loading(): <div/> }
      </div>;

    return (
      <div>
        {display}
      </div>
    );
   }
  // }
}

