import React from 'react';

import './css/login.css';

import { mystiqueAuth } from './api/mystique';
import { session_name, fluentd_s3 , allowList, checkSession} from './api/comm';
import { nexSignin, nexGrantAccess } from './api/nex';
import ReactNotify from 'react-notify';
// const requestIp = require('request-ip');

import ReactLoading from 'react-loading';


const Loading = () => (
  <ReactLoading type="spinningBubbles" color="#ff8800" height={'20%'} width={'20%'} />
);




export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.sessionData = checkSession();
    this.state = {
      account: '',
      password: '',
      env: 'prod',
      // showDialog: false,
      hits: null,
      redirect: false,
      waitcursor: false
    }
  }
  
  
  

  componentDidMount() {
    sessionStorage.removeItem(session_name);
    document.title = "Ionex Admin System";
}

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit(event) {
    event.preventDefault();
   
    const body = {
      mystiqueAccount: {
        account: this.state.account,
        password: this.state.password,
      },
      env: this.state.env
    }
    if ( allowList[this.state.env].length !== 0 && allowList[this.state.env].indexOf(this.state.account) === -1) {
      this.showNotification('ERROR','You unable login this env.');
      return
    
      }
    if (this.state.account !== '' && this.state.password !== '') {
      this.setState({waitcursor: true})
      let log_body = {
        category: "login",
        account: this.state.account,
        env: this.state.env,
       
      }
      mystiqueAuth(body)
        .then((data) => {
          delete data['mystiqueAccount'];
          if ( data.mystiqueAuth.token) {
             return nexSignin(data)
          }else {
            this.loginError();
            throw new Error('error');
          }
        })
        .then((data) => {
          
          return nexGrantAccess(data)
        })
        // .then((data) => {
          
        //   return nexGetPlans(data)
        // })
        .then((data) => {
          log_body['uid'] = data['mystiqueAuth'].uid
          log_body['status'] = 'success'
          fluentd_s3(log_body)
          // delete data['mystiqueAuth']['token'];
          delete data['mystiqueAuth']['accessToken'];
          return sessionStorage.setItem(session_name, JSON.stringify(data));
         
        }).then(() => {
          // this.props.history.push("/userdata");
          window.location.reload();
        })
        .catch(err => { 
          this.loginError();
          log_body['status'] = err
          fluentd_s3(log_body) 
        });
    } else {
      // this.setState({ showDialog: true });
      this.showNotification('ERROR','Please input your email or password.');
    
    }
  }

 

 loginError(){
   this.showNotification('ERROR','Login failed'); 
 }

  showNotification(type, content) {
    if ( type === 'INFO') this.refs.notificator.info(type, content, 3000);
    if ( type === 'ERROR') {
      this.setState({
        account : '',
        password: '',
        waitcursor: false
       });
      this.refs.notificator.error(type, content, 3000);
    }
  }


 
  render() {
    const env = this.sessionData[1] ? this.sessionData[1].env : "null"
    let display = 
    <div >
      <form id="login" onSubmit={(e) => this.handleSubmit(e)}>
      <p>Ionex Admin System
      <br />(current env:{env})
        <br />
        </p>
        <label>
      <select value={this.state.env} id="env" onChange={e => this.handleChange(e)} style={{fontSize:'20px',height: "30px", width: "380px"}}>
            <option value="dev">Dev</option>
            <option value="alpha">Alpha</option>
            <option value="beta">Beta</option>
            <option value="prod">Prod</option>
          </select>
        </label>
        <br />
        <label>

          <input style={{fontSize:'15px',height: "30px", width: "370px"}}  placeholder="Your e-mail" type="email" id="account" value={this.state.account} onChange={e => this.handleChange(e)} />
        </label>
        <br />
        <label >

          <input style={{fontSize:'15px',height: "30px", width: "370px"}} placeholder="Password" type="password" id="password" value={this.state.password} onChange={e => this.handleChange(e)} />
        </label>
        <br />
        <input type="submit" value="Submit" style={{fontSize:'15px',height: "30px", width: "380px"}} />
        <ReactNotify ref='notificator' />
      {this.state.waitcursor ? Loading(): <div/> }
      </form>
    
     
    </div>;

      return (<nav>
       
        {display}
      </nav>);
  
  }
}


