import * as React from "react";
import "./css/userdata.css"
// import { nexSignin, nexGrantAccess } from './api/nex';
import { checkSession, fluentd_s3 } from './api/comm';
import { getBillingDetail, getBillingDetailsV2, getUserData, getBillsData, getBatteryHistory, getVehicleData, unlockSwapStatus, getInvoiceData, getUsageHistoryData, getMystiqueValidationData, getMystiqueUserData, getPlanHistoryData } from './api/backend';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import ReactNotify from 'react-notify';
import ReactJson from 'react-json-view';



const Loading = () => (
  <ReactLoading type="spinningBubbles" color="#ff8800" height={'20%'} width={'20%'} />
);

const options = [
  { value: 'userid', label: 'Search by user id' },
  { value: 'email', label: 'Search by user email' },
  { value: 'idcard', label: 'Search by idcard' },
  { value: 'vcu', label: 'Search by vehicle id (vcu)' },
  { value: 'plateid', label: 'Search by plate id' },
  { value: 'engineid', label: 'Search by engine id' }
];

// const s = (a: any) => JSON.stringify(a, null, 4);


let uid = ""
let pid = ""
let ctx = {}
let responseData = {}
let responseDataV2 = {}
let responseInvoiceData = {}
let responseUserData = {}
let responseBillsData = {}
let responseMystiqueValidationData = {}
let responseMystiqueUserData = {}
let responseBatteryHistoryData = {}
let responseUsageHistoryData = {}
let responseUserVehiclesData = {}
let responsePlanHistoryData = {}
let unlockstatus = 0

// const validateEmail = (email) => {
//   return String(email)
//     .toLowerCase()
//     .match(
//       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//     );
// };

const billingDetailData = (data, state) => {
  ctx['uid'] = data
  responseData = {}
  getBillingDetail(ctx)
    .then((response) => {
      console.log(response)
      responseData = response['getBillingDetail']
      state.setState({});

    })
}

const billingDetailsV2Data = (data, state) => {
  ctx['uid'] = data
  responseDataV2 = {}
  getBillingDetailsV2(ctx)
    .then((response) => {
      console.log(response)
      responseDataV2 = response['getBillingDetailsV2']
      state.setState({});

    })
}

const billsData = (data, state) => {
  ctx['uid'] = data
  responseBillsData = {}
  getBillsData(ctx)
    .then((response) => {
      console.log(response)
      responseBillsData = response['getBillsData']
      state.setState({});

    })
}

const invoiceData = (data, state) => {
  ctx['uid'] = data
  responseInvoiceData = {}
  getInvoiceData(ctx)
    .then((response) => {
      console.log(response)
      responseInvoiceData = response['getInvoiceData']
      state.setState({});
    })
}

const usageHistoryData = (data, state) => {
  ctx['uid'] = data
  responseUsageHistoryData = {}
  getUsageHistoryData(ctx)
    .then((response) => {
      console.log(response)
      responseUsageHistoryData = response['getUsageHistory']
      state.setState({});
    })
}

const planHistoryData = (data, state) => {
  ctx['uid'] = data
  responsePlanHistoryData = {}
  getPlanHistoryData(ctx)
    .then((response) => {
      console.log(response)
      responsePlanHistoryData = response['getPlanHistoryData']
      state.setState({});
    })
}

const getBatteryHistories = (data, state) => {
  ctx['uid'] = data
  responseBatteryHistoryData = {}
  getBatteryHistory(ctx)
    .then((response) => {
      console.log(response)
      responseBatteryHistoryData = response['getBatteryHistory']
      state.setState({});
    })
}


const mystiqueValidationData = (data, state) => {
  ctx['userid'] = data
  responseMystiqueValidationData = {}
  getMystiqueValidationData(ctx)
    .then((response) => {
      console.log(response)
      responseMystiqueValidationData = response['getMystiqueValidationData']
      state.setState({});
    })
}

const mystiqueUserData = (ctx, state) => {
  responseMystiqueUserData = {}
  getMystiqueUserData(ctx)
    .then((response) => {
      console.log(response)
      responseMystiqueUserData = response['getMystiqueUserData']
      state.setState({});
    })
}

const userVehiclesData = (data, state) => {
  ctx['uid'] = data
  responseUserVehiclesData = {}
  getVehicleData(ctx)
    .then((response) => {
      console.log(response)
      responseUserVehiclesData = response['getVehicleData']
      state.setState({});
    })
}


const userData = (data, state) => {
  uid = ''
  responseUserData = {}
  responseMystiqueValidationData = {}
  responseMystiqueUserData = {}
  responseData = {}
  responseDataV2 = {}
  responseInvoiceData = {}
  responseBillsData = {}
  responseBatteryHistoryData = {}
  responseUsageHistoryData = {}
  responseUserVehiclesData = {}
  responsePlanHistoryData = {}
  delete ctx['userid']
  delete ctx['idcard']
  delete ctx['email']
  delete ctx['vcu']
  delete ctx['plateId']
  delete ctx['engineNo']
  if (state.state.child === 'email'){
    ctx['email'] = data
  } else if (state.state.child === 'idcard') {
    ctx['idcard'] = data
  } else  {
    ctx['userid'] = data
  }
  getUserData(ctx)
    .then((response) => {
      console.log(response)
      let uid_temp = '';
      responseUserData = response['getUserData']
      for (let userData of responseUserData) {
        uid_temp = userData['userId']
        if (userData['vehicles']){
          break;
        }
      }
      if (responseUserData.length >= 1) {
        uid = uid_temp
      }
      return
    }).then(() => {
      console.log('unlock status :',unlockstatus)
      if (unlockstatus === 1) {
        ctx['uid'] = uid
        unlockSwapStatus(ctx)
      }
      return
    }).then(() => {
      billsData(uid, state)
    }).then(() => {
      invoiceData(uid, state)
    }).then(() => {
      billingDetailData(uid, state)
    }).then(() => {
      billingDetailsV2Data(uid, state)
    }).then(() => {
      getBatteryHistories(uid, state)
    }).then(() => {
      usageHistoryData(uid, state)
    }).then(() => {
      userVehiclesData(uid, state)
    }).then(() => {
      planHistoryData(uid, state)
    }).then(() => {
      mystiqueUserData(ctx, state)
    }).then(() => {
      mystiqueValidationData(uid, state)
    })
}

const vechicleData = (data, state) => {
  uid = ''
  responseData = {}
  if (state.state.child === 'vcu'){
    ctx['vcu'] = data
  } else if (state.state.child === 'plateId') {
    ctx['plateId'] = data
  } else  {
    ctx['engineNo'] = data
  }
  getVehicleData(ctx)
    .then((response) => {
      console.log(response)
      responseData = response['getVehicleData']
      for (let vehicleData of responseData) {
        uid = vehicleData['userId']
      }
      return
    }).then(() => {
      userData(uid, state)
    })
}




export class NewUserdata extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 1,
      path: "",
      inputdata: "",
      env: 'prod',
      type: "userid",
      subuid: "",
      equal: "",
      child: "",
      pid: "",
      unlockswap: false,
      unlockswapstatus: 0,

      selectedOption: null,
      selectedEnvOption: null
    }
    this.sessionData = checkSession()
    ctx = { 'auth': this.sessionData[1] }
  }

  handleChange = (e) => {
    uid = ''
    this.setState({
      [e.target.id]: e.target.value.replace(/\s/g, ''),
      unlockswap: false,
      unlockswapstatus: 0,
    })
    unlockstatus = 0
    console.log('unlock status',unlockstatus);
    e.preventDefault();
  }

  handleChangeCheck = (e) => {
    console.log(this.state.unlockswap)
    if (this.state.unlockswap === false) {
      this.setState({
        unlockswap: true,
      })
      unlockstatus = 1
    } else {
      this.setState({
        unlockswap: false,
      })
      unlockstatus = 0
    }
    this.setState({});
    console.log('unlock status',unlockstatus);
  }

  selectHandleChange = (selectedOption) => {
    uid = ''
    this.setState({
      type: selectedOption.value,
      selectedOption,
      inputdata: "",
      unlockswap: false,
      unlockswapstatus: 0,
    })
    unlockstatus = 0
    console.log('unlock status',unlockstatus);
  };


  handleSubmit(e) {
    pid = ""
    uid = ""
    // ctx = {}
    this.setState({ equal: "", child: "", subuid: "", path: "", limit: 1 })
    if (this.state.inputdata === "") { this.showNotification('ERROR', 'Please input search data') }
    else {
      this.setState({ waitcursor: true })
      let log_body = {
        category: "searchUserData",
        env: this.sessionData[1].env,
        uid: this.sessionData[1]['mystiqueAuth'].uid,
        inputdata: this.state.inputdata,
        method: this.state.type
      }
      const promise = new Promise(function (resolve, reject) {
        resolve()
      })

      promise.then(authUser => {


          if (this.state.type === "userid") {
            
              this.setState({ equal: this.state.inputdata, child: "uid" })
              userData(this.state.inputdata, this);

          } else if (this.state.type === "vcu") {
              this.setState({ equal: this.state.inputdata, child: "vcu" })
              vechicleData(this.state.inputdata, this);


          } else if (this.state.type === "plateid") {
              this.setState({ equal: this.state.inputdata, child: "plateId" })
              vechicleData(this.state.inputdata, this);

          } else if (this.state.type === "engineid") {
              this.setState({ equal: this.state.inputdata, child: "engineNo" })
              vechicleData(this.state.inputdata, this);

          } else if (this.state.type === "email") {
              this.setState({ equal: this.state.inputdata, child: "email", limit: 5 })
              userData(this.state.inputdata, this);

          } else if (this.state.type === "idcard") {
              this.setState({ equal: this.state.inputdata, child: "idcard", limit: 5 })
              userData(this.state.inputdata, this);

          }


          log_body['status'] = 'success'
          fluentd_s3(log_body)
          this.setState({ waitcursor: false,  inputdata: ""})
          

        })
        .catch(error => {
          console.log(error)
          this.showNotification('ERROR', 'Unable find user data or you need re-login account');
          log_body['status'] = error
          fluentd_s3(log_body)
          this.setState({ waitcursor: false, unlockswap: false })
          unlockstatus = 0
        });
    }

    e.preventDefault();
  };



  componentDidMount() {
    this.session = checkSession()
    if (this.session[0]) {
      document.title = "Ionex Admin System";
    } else {
      // this.props.history.push("/login");
    }
  }

  showNotification(type, content) {
    if (type === 'INFO') this.refs.notificator.info(type, content, 3000);
    if (type === 'ERROR') {
      this.setState({
        path: '',
        waitcursor: false
      });
      this.refs.notificator.error(type, content, 3000);
    }
  }

  render() {
    const env = this.sessionData[1] ? this.sessionData[1].env : "null"
    const { selectedOption } = this.state;

    let display =
      <div id="parent">
        <ReactNotify ref='notificator' />
        <pre>車主資料查詢_New
          (env:{env})
          </pre>
        <form onSubmit={(e) => this.handleSubmit(e)}>

          <Select
            id="type"
            name="type"
            value={selectedOption || options[0]}
            onChange={this.selectHandleChange}
            options={options}
            placeholder="Select query method"
          />
          <label>
            <input placeholder="Please input data" type="text" id="inputdata" value={this.state.inputdata} onChange={e => this.handleChange(e)} />
          </label>
          <br />
          <input type="submit" value="Submit" style={{ fontSize: '15px', height: "30px", width: "380px" }} />
          <br />
          <input type="checkbox" name="unlockswap" value="1"  checked={this.state.unlockswap} onChange={e => this.handleChangeCheck(e) } />
          Unlock SWAP status
          <br />
          {this.state.waitcursor ? Loading() : <div />}
        </form>
        <div align="left"  >
          UserData  <ReactJson src={responseUserData} />
        </div>
        <br></br>
        <div align="left"  >
          BillsData  (new to old) <ReactJson src={responseBillsData} />
        </div>
        <br></br>
        <div align="left"  >
          Vehicles <ReactJson src={responseUserVehiclesData} />
        </div>
        <br></br>
        <div align="left"  >
          InoviceData  (new to old) <ReactJson src={responseInvoiceData} />
        </div>
        <br></br>
        <div align="left"  >
          BillingDetailsV2 (new to old) <ReactJson src={responseDataV2} />
        </div>
        <br></br>
        <div align="left"  >
          usageHistory (new to old) <ReactJson src={responseUsageHistoryData} />
        </div>
        <br></br>
        <div align="left"  >
          planHistory <ReactJson src={responsePlanHistoryData} />
        </div>
        <br></br>
        <div align="left"  >
          BatteryHistory (new to old) <ReactJson src={responseBatteryHistoryData} />
        </div>
        <br></br>
        <div align="left"  >
          Mystique User Data <ReactJson src={responseMystiqueUserData} />
        </div>
        <br></br>        
        <div align="left"  >
          BillingDetail (new to old) <ReactJson src={responseData} />
        </div>
        <br></br>
        <div align="left"  >
          Mystique Validation Data <ReactJson src={responseMystiqueValidationData} />
        </div>
      </div>;
    return (
      <div>
        {display}
      </div>
    );
  }
}
