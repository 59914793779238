import * as React from "react";

import "./css/deletebilling.css"
import { checkSession , fluentd_s3} from './api/comm';
import { nexDeleteBillingDetail,nexGrantAccess, nexSignin} from './api/nex';
import { getBillingDetail } from './api/backend';
import ReactNotify from 'react-notify';
import ReactLoading from 'react-loading';
import Moment from 'moment';


const Loading = () => (
  <ReactLoading type="spinningBubbles" color="#ff8800" height={'20%'} width={'20%'} />
);
console.log('day - ', Moment().format("DD"))

let deletedbillingdetail = []
let ctx = {}

export class Deletepenalty extends React.Component {
  constructor(props) {
    super(props);
  
    this.sessionData =  checkSession()
   
    this.state = {
      uid: "",
      reason1: "",
      env: 'prod',
      waitcursor: false
    }
    this.sessionData = checkSession()
    ctx = { 'auth': this.sessionData[1] }
  }
  
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value.replace(/\s/g, ''),
    })
    e.preventDefault();
  }

  handleSubmit(event) {
    if (this.state.uid === "" || this.state.reason1 ==="") {
      this.showNotification('ERROR','Please input User id or reason (like jira ticket number)');
      // return
    } else {
      this.setState({
        waitcursor: true
       });
    let log_body = {
      category: "deleteBillingDetail",
      env: this.sessionData[1].env,
      uid: this.state.uid,
      reason: this.state.reason1
    }
    let responseData = {}

    nexSignin(this.sessionData[1])
    .then((data) => {
        return nexGrantAccess(data)
    }).then((data) => {
      console.log('day - ', Moment().format("DD"))
      let starttime = Moment().date(1).hour(0).minute(0).second(0).unix() * 1000;
      if ( Moment().format("DD") === '01') {
          starttime = Moment().subtract(1, 'months').date(1).hour(0).minute(0).second(0).unix() * 1000;
      }
      const endtime = Moment().endOf('month').unix() * 1000;
      ctx['uid'] = this.state.uid;
      ctx['starttime'] = starttime
      ctx['endtime'] = endtime
      return getBillingDetail(ctx)
    }).then((data) => {
      let reason1 = this.state.reason1
      let sessionData1 = this.sessionData[1]
      let setState1 = this.setState
      responseData = data['getBillingDetail']
      deletedbillingdetail = []
      let count = 0
      responseData.forEach(function(billingDetail) {
          if ( billingDetail['transactionId'].includes('-penalty-')) {
              count = count + 1
              console.log(billingDetail)
              const transctionId = billingDetail['transactionId']
              Promise.resolve()
              .then(() => deletedbillingdetail.push({"transactionId": transctionId}))
              .then(() => nexDeleteBillingDetail(Object.assign({tid: transctionId,  body:{text:reason1}}, sessionData1)))
              .catch(error => {
                deletedbillingdetail.push({"transactionId": `Unable delete  transction Ids  - ${transctionId}  - ${error}`})
              })
              .finally(() => setState1);
          }
      });
      if ( count === 0 ){
        deletedbillingdetail.push({"transactionId": `No any penalty billing data in this month`})
      }
        return 
    }).then(data => {
        this.setState({
          uid: '',
          reason1: '',
          waitcursor: false
         });
         log_body['status'] = 'success'
         log_body['tids'] = deletedbillingdetail
         this.setState({waitcursor: false})
       return fluentd_s3(log_body)
    
      })
      .catch(error => {
           this.showNotification('ERROR',`Unable delete  billing - ${this.state.uid} or you need re-login account`);
           log_body['status'] = error
           console.log(error)
           log_body['tids'] = deletedbillingdetail
           fluentd_s3(log_body)
           this.setState({waitcursor: false})
      });
    }
    event.preventDefault();
  }


  componentDidMount() {
    const session = checkSession()
    if (!session[0]){
        // this.props.history.push("/login");
    }
    document.title = "Noodoe Admin System";
    
  }

  showNotification(type, content) {
    
    if ( type === 'INFO') this.refs.notificator.info(type, content, 3000);
    if ( type === 'ERROR') {
      this.setState({
        uid: '',
        reason1: '',
        waitcursor: false
       });
      this.refs.notificator.error(type, content, 3000);
    }
  }
  
  render() {
    const env = this.sessionData[1] ? this.sessionData[1].env : "null";
    const listItems = deletedbillingdetail.map((d) => <li key={d.transactionId}>{d.transactionId}</li>);
    let display =
      <div id="parent">
        <pre>刪除當月違約金費用 (current env:{env}) <br/>
          </pre>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <label>

            <input placeholder="請輸入 User ID" type="text" id="uid" value={this.state.uid} onChange={e => this.handleChange(e)} />
          </label>
          <br/>
          <label>
            <input placeholder="Please input the reason" type="text" id="reason1" value={this.state.reason1} onChange={e => this.handleChange(e)} />
          </label>
          <br />
          <input type="submit" value="Submit" style={{ fontSize: '24px', height: "30px", width: "600px" }} />
        </form>
        <ReactNotify ref='notificator' />
        {this.state.waitcursor ? Loading(): <div/> }
        <div ><p> 己刪除以下 Transction ID</p></div> 
        <div id="deletedbillingdetail">{listItems}</div> 
      </div>;

    return (
      <div>
        {display}
      </div>
    );
  }
 
}

