import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// import { Changeplan } from './changeplan';
import { Update21UserPassword } from './updateuserpw';
import { Login } from './login';
// import { Userdata } from './userdata';
import { NewUserdata } from './userdata.mongo';
import { Deletebilling } from './deletebilling';
// import { FindCabinetData } from './findCabinetData';
import { Cabinetvisible } from './cabinetvisible';
import { GetBatteryHistory } from './getBatteryHistory.mongo';
// import { UploadCabinetFW } from './uploadCabinetFW';
import { Deletepenalty } from './deletepenalty';
import { Pointdata } from './pointdata';

import {  checkSession, speciallist } from './api/comm';



let sessionData = checkSession()
class ProfileTabs extends React.PureComponent {
  state = { activeIndex: 0 }
  // sessionData = checkSession()
  handleChange = (_, activeIndex) => this.setState({ activeIndex })
  render() {
    const { activeIndex } = this.state;
    return (
      // <Router>
      <div
        style={{
          display: 'flex',
        }}
      >

        
       
        { sessionData[0]  && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  === -1  ?
        <VerticalTabs
          value={activeIndex}
          onChange={this.handleChange}
        >
          <MyTab label='車主資料查詢_New' />
          <MyTab label='經銷商資料查詢' />
          <MyTab label='刪除違約金' />
          <MyTab label='刪除交易費用' />
          <MyTab label='電櫃上下架' />
          {/* <MyTab label='搜尋電櫃資料' /> */}
          <MyTab label='Search Battery History' />
          <MyTab label='更新 Ionex 2.1 使用者初始密碼 ' />
          <MyTab label='Login' />
          {/* <MyTab label='車主資料查詢_Old' /> */}
          {/* <MyTab label='Upload Cabinet FW' /> */}
        </VerticalTabs>
       
         : <div/>}

      { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  > -1 ?
        <VerticalTabs
          value={activeIndex}
          onChange={this.handleChange}
        >
          <MyTab label='Search Battery History' />
          <MyTab label='Login' />
        </VerticalTabs>
       
       : <div/> }

      { !sessionData[0] ?
        <VerticalTabs
          value={activeIndex}
          onChange={this.handleChange}
        >
          <MyTab label='Login' />
        </VerticalTabs>
       
       : <div/> }
      
        { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  === -1 && activeIndex === 7 &&   <TabContainer> < Login /></TabContainer> }
        { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  === -1 && activeIndex === 6 && <TabContainer> <Update21UserPassword /></TabContainer> }
        {/* { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  === -1 && activeIndex === 8 && <TabContainer><Userdata /></TabContainer> } */}
        { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  === -1 && activeIndex === 3 && <TabContainer><Deletebilling /></TabContainer> }
        {/* { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  === -1 && activeIndex === 5 && <TabContainer><FindCabinetData /></TabContainer> } */}
        { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  === -1 && activeIndex === 5 && <TabContainer><GetBatteryHistory /></TabContainer> }
        { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  === -1 && activeIndex === 4 && <TabContainer><Cabinetvisible /></TabContainer> }
        { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  === -1 && activeIndex === 2 && <TabContainer><Deletepenalty /></TabContainer> }
        { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  === -1 && activeIndex === 1 && <TabContainer><Pointdata /></TabContainer> }
        { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email)  === -1 && activeIndex === 0 && <TabContainer><NewUserdata /></TabContainer> }

        { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email) > -1 && activeIndex === 0 && <TabContainer><GetBatteryHistory /></TabContainer> }
        { sessionData[0] && speciallist.indexOf(sessionData[1]['mystiqueAuth'].email) > -1 && activeIndex === 1 &&   <TabContainer> < Login /></TabContainer> }

        { !sessionData[0] && activeIndex === 0 &&   <TabContainer> < Login /></TabContainer> }
        

    </div>
    // </Router>
    )
  }
}

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: 'column'
  },
  indicator: {
    display: 'none',
  }
}))(Tabs)

const MyTab = withStyles(theme => ({
  selected: {
    color: 'tomato',
    borderBottom: '2px solid tomato',
    fontSize: '24px'
  }
}))(Tab);

function TabContainer(props) {
  return (
    <Typography component="div" >
      {props.children}
      
    </Typography>
  );
}

export default ProfileTabs;