import * as React from "react";

import "./css/deletebilling.css"
import { checkSession , fluentd_s3} from './api/comm';
import { nexDeleteBillingDetail,nexGrantAccess, nexSignin} from './api/nex';
import ReactNotify from 'react-notify';

import ReactLoading from 'react-loading';


const Loading = () => (
  <ReactLoading type="spinningBubbles" color="#ff8800" height={'20%'} width={'20%'} />
);


export class Deletebilling extends React.Component {
  constructor(props) {
    super(props);
  
    this.sessionData =  checkSession()
   
    this.state = {
      tid: "",
      reason1: "",
      env: 'prod',
      waitcursor: false
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value.replace(/\s/g, ''),
    })
    e.preventDefault();
  }

  handleSubmit(event) {
    if (this.state.tid === "" || this.state.reason1 ==="") {
      this.showNotification('ERROR','Please input transction id or reason (like jira ticket)');
      // return
    } else {
      this.setState({
        waitcursor: true
       });
    let log_body = {
      category: "deleteBillingDetail",
      env: this.sessionData[1].env,
      uid: this.sessionData[1]['mystiqueAuth'].uid,
      tid: this.state.tid,
      reason: this.state.reason1
    }

    nexSignin(this.sessionData[1])
    .then((data) => {
        return nexGrantAccess(data)
    }).then((data) => {
      return nexDeleteBillingDetail( Object.assign({tid: this.state.tid, body:{text:this.state.reason1}}, this.sessionData[1]))
    }).then(data => {
        this.showNotification('INFO',`Delete ${this.state.tid} Done`);
        this.setState({
          tid: '',
          reason1: '',
          waitcursor: false
         });
         log_body['status'] = 'success'
         this.setState({waitcursor: false})
       return fluentd_s3(log_body)
    
      })
      .catch(error => {
           this.showNotification('ERROR',`Unable delete  billing - ${this.state.tid} or you need re-login account`);
           log_body['status'] = error
           fluentd_s3(log_body)
           this.setState({waitcursor: false})
      });
    }
    event.preventDefault();
  }


  componentDidMount() {
    const session = checkSession()
    if (!session[0]){
        // this.props.history.push("/login");
    }
    document.title = "Noodoe Admin System";
    
  }

  showNotification(type, content) {
    
    if ( type === 'INFO') this.refs.notificator.info(type, content, 3000);
    if ( type === 'ERROR') {
      this.setState({
        tid: '',
        reason1: '',
        waitcursor: false
       });
      this.refs.notificator.error(type, content, 3000);
    }
  }
  
  render() {
    const env = this.sessionData[1] ? this.sessionData[1].env : "null";
   
    let display =
      <div id="parent">
        <pre>刪除交易費用 (current env:{env}) <br/>可以透過搜尋車主資料裹面的 BillingDetail 找尋 transction id
          </pre>
        <form onSubmit={(e) => this.handleSubmit(e)}>
          <label>

            <input placeholder="請輸入 Transction ID" type="text" id="tid" value={this.state.tid} onChange={e => this.handleChange(e)} />
          </label>
          <br/>
          <label>
            <input placeholder="Please input the reason" type="text" id="reason1" value={this.state.reason1} onChange={e => this.handleChange(e)} />
          </label>
          <br />
          <input type="submit" value="Submit" style={{ fontSize: '24px', height: "30px", width: "600px" }} />
        </form>
        <ReactNotify ref='notificator' />
        {this.state.waitcursor ? Loading(): <div/> }
      </div>;

    return (
      <div>
        {display}
      </div>
    );
   }
  // }
}

