
import { axiosRequest, server } from './comm.js'
const querystring = require('querystring');

const RequestSample = {
    "app": "ionex.admin.system",
    "dn": "ionex.admin",
    "dm": "react",
    "vn": "v1.0.0",
    "ts": Math.floor(Date.now()),
    "tz": 480,
    "cn": "TW",
}


export async function  mamacocoSignin(jsondata) {
    // console.log(jsondata)
    let ap =  server[jsondata.env].mamacocoAP.replace("\n","")
    const data = {
        method: 'post',
        url : `${server[jsondata.env].mamacoco}/private/v1/users/signIn`,
        postData : {account: ap.split('/')[0], password: ap.split('/')[1]},
        config : {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
    // console.log(data)
    jsondata['mamacocoSignin'] = await axiosRequest(data)
    // console.log(jsondata['mamacocoSignin'])
    return jsondata
} 

