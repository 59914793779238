import { axiosRequest, server } from './comm.js'

export async function  mystiqueAuth(jsondata) {
    const data = {
        method: 'post',
        url : `${server[jsondata.env].mystique}/api/v1/user/auth`,
        postData : {
            asp: 'ep',
            sc: 'knex',
            email: jsondata.mystiqueAccount.account,
            password:jsondata.mystiqueAccount.password
        },
        config : {
          headers: {
              'x-api-key' : server[jsondata.env].mystiqueApiKey
          }
        }
    }
    jsondata['mystiqueAuth'] = await axiosRequest(data)
    return jsondata
} 