
import { axiosRequest, server } from './comm.js'
import { mamacocoSignin } from './mamacoco.js'
const querystring = require('querystring');

const RequestSample = {
    "app": "ionex.admin.system",
    "dn": "ionex.admin",
    "dm": "react",
    "vn": "v1.0.0",
    "ts": Math.floor(Date.now()),
    "tz": 480,
    "cn": "TW",
}


export async function  nexSignin(jsondata) {
    // console.log(jsondata)
    const data = {
        method: 'post',
        url : `${server[jsondata.env].nex}/api/v1/user/signIn`,
        postData : {},
        config : {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : `Bearer ${jsondata.mystiqueAuth.token}`
          }
        }
    }
    jsondata['nexSignin'] = await axiosRequest(data)
    return jsondata
} 

export async function  nexGrantAccess(jsondata) {
    const data = {
        method: 'post',
        url : `${server[jsondata.env].nex}/api/v1/user/grantAccess`,
        postData : {},
        config : {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : `Bearer ${jsondata.nexSignin.token}`
          }
        }
    }
    jsondata['nexGrantAccess'] = await axiosRequest(data)
    return jsondata
}

export async function  nexGetUserInfo(jsondata) {
    const data = {
        method: 'get',
        url : `${server[jsondata.env].nex}/api/v1/users/me`,
        postData : {},
        config : {
          headers: {
            //   'Content-Type': 'application/json',
              'authorization' : `Bearer ${jsondata.nexGrantAccess.token}`
          }
        }
    }
    jsondata['nexGetUserInfo'] = await axiosRequest(data)
    return jsondata
} 

export async function  nexRefreshToken(jsondata) {
    const data = {
        method: 'post',
        url : `${server[jsondata.env].nex}/api/v1/user/refreshToken`,
        postData : {},
        config : {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : `Bearer ${jsondata.nexSignin.token}`
          }
        }
    }
    jsondata['nexGrantAccess'] = await axiosRequest(data)
    return jsondata
} 

export async function  nexBatteryPlan(jsondata) {
    let body = Object.assign({}, RequestSample);
    body = { ...body, ...jsondata.body };
    const data = {
        method: 'put',
        url : `${server[jsondata.env].nex}/private/v1/batteries/${jsondata.bid}/plan`,
        postData : body,
        config : {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : `Bearer ${jsondata.nexGrantAccess.token}`
          }
        }
    }
   
    return await axiosRequest(data)
} 


export async function  nexUpdateUserPassword(jsondata) {
    let body ={};
    body = { ...body, ...jsondata.body };
    const data = {
        method: 'patch',
        url : `${server[jsondata.env].nex}/private/v2/user/adminUpdatePassword`,
        postData : body,
        config : {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : `Bearer ${jsondata.nexGrantAccess.token}`
          }
        }
    }
   
    return await axiosRequest(data)
} 

export async function  nexGetPlans(jsondata) {
    let body = Object.assign({}, RequestSample);
    body = { ...body, ...jsondata.body };
    const data = {
        method: 'get',
        url : `${server[jsondata.env].nex}/api/v1/plans?${querystring.stringify(body)}`,
        config : {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : `Bearer ${jsondata.nexGrantAccess.token}`
          }
        }
    }
    jsondata['nexGetPlans'] = await axiosRequest(data)
    return jsondata
} 

export async function  nexDeleteBillingDetail(jsondata) {
    let body = Object.assign({}, RequestSample);
    body = { ...body, ...jsondata.body };
    const data = {
        method: 'delete',
        url : `${server[jsondata.env].nex}/private/v1/billingDetail/${jsondata.tid}?${querystring.stringify(body)}`,
        // postData : body,
        config : {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : `Bearer ${jsondata.nexGrantAccess.token}`
          }
        }
    }
    console.log(data)
    return await axiosRequest(data)
} 

export async function  nexUpdateEngryStation(jsondata) {
    let body = Object.assign({}, RequestSample);
    let domain, token;
    // console.log(jsondata)
    if (jsondata.customer === 'ionextw') {
       domain = server[jsondata.env].nex
       token = jsondata.nexGrantAccess.token
    } else {
       domain = server[jsondata.env].mamacoco
       token = await mamacocoSignin(jsondata)
    }
    
    body = { ...body, ...jsondata.body };
    const data = {
        method: 'patch',
        url : `${domain}/private/v1/estations/${jsondata.eid}`,
        postData : body,
        config : {
          headers: {
              'Content-Type': 'application/json',
              'authorization' : `Bearer ${token}`
          }
        }
    }
    // console.log(data)
    return await axiosRequest(data)
} 